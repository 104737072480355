import React from 'react'
import LunchBanner from './LunchBanner'
import Lunch from './Lunch'

function DagensLunch() {
  return (
    <div>
        <LunchBanner/>
        <Lunch/>
      
    </div>
  )
}

export default DagensLunch
