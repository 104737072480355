export const FavoriteData = [
    {id:'1',
    img:'images/phoDT.jpg',
    text:'Pho Biff',
    textSub: 'Risnudelsoppor med ryggbiff, kycklingfile eller tofu'

    },

    {id:'2',
    img:'images/bunthitDT.jpg',
    text:'Bun thit',
    textSub:'Friterad vårrullar med risnudlar, fläsk, kyckling eller tofu'
    },

    {id:'3',
    img:'images/comboDT.jpg',
    text:'Com Bo',
    textSub: 'Wokad biff med paprika, purjolök, ostronsås, serveras med ris'
    
    },
    {id:'4',
    img:'images/goicuonDT.jpg',
    text:'Goi cuon',
    textSub:'Färska vårullar med räkor, fläsk, risnudlar och grönsaker'
    },
   /* {id:'5',
    img:'images/bunbohueDT.jpg',
    text:'Bun Bo Hue',
    textSub:'Risnudlar i oxbuljong med biff, fläsk, korv och grönsaker '
    }*/


]

   