import React from "react";
import "./Home.css";
import Lunch from "./Lunch";
import Favorite from "./Favorite";
import { Link } from "react-router-dom";
import { FaChevronRight } from "react-icons/fa";

function Home() {
  return (
    <div>
      <div className="home-container">
        <div className="home-text-content">
          <h1 className="home-title">Vietnamese cuisine</h1>
          <h3 className="sub">Upptäck vår meny erbjudande</h3>
          <div className="home-link-nexticon">
            <Link className="home-link" style={{ fontSize: "20px" }} to="/meny">
              {" "}
              MENY
            </Link>

            <FaChevronRight className="nexticon" size={18} />
          </div>
        </div>
        {/* <div className ='home-image'>
      <img src='images/home.png' alt='nudelsoppa'/>
      </div>*/}
      </div>

      <Favorite />
      <div style={{ backgroundColor: "rgba(0, 129, 68, 0.05)" }}>
        <h2 className="lunch-title" style={{ paddingTop: "50px" }}>
          Dagens lunch
        </h2>
        <p className="lunch-sub-info">Serveras mellan 11:00 - 14:30 | 119:- </p>
        <div style={{ marginTop: "-45px" }}>
          <Lunch />
        </div>
      </div>
    </div>
  );
}

export default Home;
