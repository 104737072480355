import React from 'react';
import './Lunch.css'

function LunchBanner() {
  return (
    <div>
    <div className='lunch-hero-image'>
      <div className='lunch-hero-title'>
      <h2 className ='lunch-hero-title-h2'style={{color:'white', marginBottom:'-15px', textAlign:'center'}}>DAGENS LUNCH</h2>
      <h4 className ='lunch-hero-title-h4'style={{color:'white', marginBottom:'-15px', textAlign:'center'}}>Ingår sallad, saft och kaffe</h4>
      <h4 className ='lunch-hero-title-h4'style={{color:'white', marginBottom:'-15px',textAlign:'center'}}> Serveras mellan 11:00 - 14:30</h4>
      <h4 className ='lunch-hero-title-h4'style={{color:'white', textAlign:'center'}}> 119:-</h4>
      
      </div>
    </div>
      
    </div>
  )
}

export default LunchBanner
